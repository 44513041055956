@import url('https://fonts.googleapis.com/css?family=Raleway|Shadows+Into+Light');

$primary: #e83242; /* MAIN COLOR */
$secondary: #4a4d73; /* SECONDARY COLOR */
$blk: #333; /* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */
$wht: #fff;
$border-radius: 0px; /* USE THIS TO SET THE BORDER RADIUS FOR BUTTONS */
$footerLinks: #fff;
html,body {
  height: 100%;
}

body {
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  font-family: 'Raleway', sans-serif;
  h1,h2,h3,h4,h5,h6 {
    font-family: 'Shadows Into Light', cursive;
  }
}

.flash {
	display:none;
}

q,blockquote {
    quotes: "“" "”";
}

.navbarFixed {
 position: fixed;
 width: 100%;
 top: 0;
 z-index: 999;
}


/* Change autocomplete styles in WebKit */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus
input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid #0065dd;
  -webkit-text-fill-color: #0065dd;
  -webkit-box-shadow: 0 0 0px 1000px #000 inset;
  transition: background-color 5000s ease-in-out 0s;
}


nav.navbar {
	z-index: 1000;
	border: none;
	border-radius: $border-radius;

	.navbar-nav {

	    > li > a {
			text-align: center;
			margin-top: 22px;
			display: flex;
			align-items: center;
			color: $blk;

			@media (max-width: 767px) {
				  margin-top: 0;
				  padding: 6px 0px;
			    display: inline-block;
			}

		    &:hover {
		    	background: $primary;
		    	color:$wht;
		    	@media (max-width: 1024px) {
			    	background: transparent;
	    			color: $blk;
				  	outline: 0;
		    	}
		    }

			&:focus, &:active {
			  background: transparent;
			  color: $blk;
			  outline: 0;
			}
		}
	}
}

.navbar-right {
	margin-top: 0px;
}
.navbar-toggle {
    margin: 20px 15px 8px 0px;
    border: 1px solid transparent;
    border-radius: 4px;
}


/** LOGIN FORM **/
@mixin btn {
	background: $primary;
	color: $wht;
	padding: 0.8em 2em;
	font-size: 1em;
	font-weight: 400;
	text-align: center;
	text-transform: uppercase;
	letter-spacing: 1px;
	display: inline-block;
	margin: 1em 0em;
	transition: 0.7s ease all;
  text-decoration: none;

	&:hover {
    background: $secondary;
    color: $wht;
		text-decoration: none;

			@media (max-width: 1024px) {
        background: $primary;
      	color: $wht;
			}
	}
}

.btn-default {
	@include btn;
}

.modal-dialog {
	max-width: 300px;
	text-align: center;
	margin: 6em auto;

	.close {display: none;}

	.modal-content {
		color: $primary;

		h2 {
			text-align:center;
		}
	}

	input {
		border: 1px solid #777;
		text-align: center;
	}

    input#username, input#password {
  		@media (max-width: 1024px) {
   			font-size: 16px;
  		}
 	}

	button {
		background: transparent;
		color: $wht;
		display: block;
		border:none;
		padding: 1em 2em;
		margin: 0 auto;
		font-size: 18px;

		&:hover {
			background: transparent;
			color: #fff;
			box-shadow: none;
			text-shadow: 0px 0px 3px $blk;
			border:none;
		}
	}

	input[type="submit"] {
		@include btn;
		display: block;
		width:100%;
		font-size: 18px;
    	margin: 0 auto;
	}

}

.modal-header, .modal-footer {
	background: $primary;
	color: $wht;
}

.modal-open {
  overflow: auto;
  padding-right: 0px !important;
}

input#username {
	margin-bottom: 20px;
}


/** END LOGIN FORM **/
//section start
section{
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 160px 0;
  @media(max-width: 1024px){
    padding: 130px 0;
  }
  @media(max-width: 991px){
    padding: 100px 0;
  }
  @media(max-width: 767px){
    padding: 70px 0;
  }
}
.section-a {
  background-image: url('../img/bg1.jpg');
  padding: 250px 0;
  @media(max-width: 1024px){
    padding: 220px 0;
  }
  @media(max-width: 991px){
    padding: 190px 0;
  }
  @media(max-width: 767px){
    padding: 150px 0;
  }
  @media(max-width: 407px){
    padding:80px 0;
  }
  @media(max-width: 370px){
    padding:50px 0;
  }
  h1 {
    color: $wht;
    @media(max-width: 767px){
      font-size: 2em;
    }
  }
}
.section-b {
  background-color: $secondary;
  color: $wht;
  h1 {
    font-size: 6em;
    @media(max-width: 767px){
      font-size: 4em;
      text-align: left;
    }
    @media(max-width:460px){
      font-size: 3em;
    }
    @media(max-width:375px){
      font-size: 2em;
      text-align: left;
    }
  }
  @media(max-width: 767px){
    padding: 20px 0;
  }
  p{
    line-height: 1.8em;
    font-size: 1em;
    @media(max-width: 767px){
      font-size: .9em;
    }
  }
}
.section-c {
  padding: 60px 0;
  h1 {
    font-size:3em;
    color: $primary;
    @media(max-width:460px){
      font-size: 2em;
    }
  }
  p{
    line-height: 1.8em;
    font-size: 1em;
    text-align: center;
    padding: 25px;
    @media(max-width:460px){
      padding: 0px;
        font-size: .9em;
    }
  }
  img {
    box-shadow: 8px 2px 0px $secondary;
  }
}
.section-d {
  background-image: url('../img/bg2.jpg');
  padding: 160px 0;
  @media(max-width: 1024px){
    padding: 130px 0;
  }
  @media(max-width: 991px){
    padding: 100px 0;
  }
  @media(max-width: 767px){
    padding: 50px 0;
  }
  @media(max-width: 600px){
    padding: 30px 0;
  }
  h1 {
    font-size:3em;
    color: $wht;
    @media(max-width:460px){
      font-size: 1.5em;
    }
  }
  p{
    line-height: 1.8em;
    color: $wht;
    font-size: 1.3em;
    text-align: center;
    padding: 25px;
    @media(max-width:460px){
      font-size: .9em;
      padding: 0px;
    }
  }
}
// section ends
footer {
	padding: 50px 0px 20px;
	background: $primary;
	color: $footerLinks;
	font-size: 15px;


	a {
		color: $footerLinks;
		white-space: nowrap;
		font-size: 15px;

		@media (max-width: 990px) {
			font-size: 13px;
		}

		&:hover{
			color: lighten($footerLinks, 10%);
			outline: 0 !important;
 			text-decoration: none;
		}

		&:focus {
			color: $wht;
			outline: 0 !important;
 			text-decoration: none;
		}
	}

	p {
		font-size: 15px;

		@media (max-width: 990px) {
			font-size: 13px;
		}
	}
}


input#username, input#password {
	width: 100%;

	@media (max-width: 1024px) {
  		font-size: 16px;
	}
}





select {
    -moz-appearance: none;
    text-indent: 0.01px;
    text-overflow: '';
}


a,a:hover,a:focus,a:active {
  outline: 0 !important;
}


.logo {
	max-width: 250px;
	padding: 0.5em;
}


@media(max-width: 767px) {
	.logo {
		max-width: 175px;
	}

    .navbar-header a {
        float: left;
    }
}

@media (max-width: 600px) {
  .logo {
    max-width: 125px;
  }

  .navbar-toggle {
    margin-top: 5px;
  }

}


.top-pad {
	padding: 3em;
	background: white;
}


.flex {
	display: flex;
	justify-content: center;
	align-items: center;

	@media (max-width: 1024px) {
		display: block;  /* may need to be inline-block */
	}
}
